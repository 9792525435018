/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #d5dbe9;
  min-height: 100vh;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.MuiCardHeader-title {
  color: #c5a87cfc!important;
  font-size: 1.2rem!important;
  font-weight: bold!important;
  padding: 0;
}

.MuiCardHeader-subheader {
  color: #c5a87cfc!important;
  font-size: 0.8rem !important;
  font-weight: bold !important;
  padding: 0;
  text-align: center;
}

.MuiCardContent-root {
  padding: 0!important;
}

.navbar-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  grid-column-gap: 50px;
}

.iq-image {
  position: absolute;
  top: 0%;
  left: 40%;
  width: 60%;
  transition: width 5s;
  transition-timing-function: linear;
  z-index: 0;
}

.iq-image:hover {
  width: 30%;
}

.cookie-banner{
  position:absolute;
  top: 630px;
  left: 38%;
  border: 4px solid #6da4b5;
  padding: 15px;
}

@media(max-width: 700px) {
  .cookie-banner {
    font-size: 30px;
    top: 18rem;
    left: 0%;
    color: white,
  }
}


.about {
  width: 100%;
}

.about-text-header {
  margin-left: auto;
  margin-right: auto;
}

.about-heading {
  /* font-family: "DIN W01 Bold"; */
  font-size: 34px;
  color: #394e6f;
  margin-top: 4.5rem;
  text-align: center;
}

.about-subheading {
  /* font-family: "DIN W01 Bold"; */
  font-size: 30px;
  color: #394e6f;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.topic-header-about {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}

.topic-header {
  text-align: center
}

.lorem {
  color: #394e6f;
  /* font-family: "DIN W01 Bold"; */
  font-size: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.lorem1 {
  color: #394e6f;
  /* font-family: "DIN W01 Bold"; */
  font-weight: bold;
  font-size: 25px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.lorem1_0 {
  color: #6da4b5;
  /* font-family: "DIN W01 Bold"; */
  font-weight: 900;
  font-size: 25px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.lorem2 {
  color: #394e6f;
  text-shadow: 1px 1px 2px #000000;
  /* font-family: "DIN W01 Bold"; */
  font-size: 20px;
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.lorem2:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.title {
  color: #647787;
  font-weight: 800!important;
}

.subtitle {
color: #647787;
  font-weight: 400 !important;
}

.paper-back {
  background: linear-gradient(-45deg, #6ca4b5, white, white, #6ca4b5);
  display: block;
}

.oem-carousel {
  display: block;
  max-width: 70%;
  height: auto;
  min-height: 630px;
  opacity: 0.7;
  margin: 0 auto;
}


 @media(max-width: 700px) {
   .oem-carousel {
     min-height: 200px;
     max-width: 90%;
   }
 }

 @media(max-width: 1200px) {
   .oem-carousel {
     min-height: 200px;
     max-width: 100%;
   }
 }

.carousel-title {
  display: block;
  text-align: center;
}

.carousel-home-title {
    display: inline;
    /* text-align: center;
    font-size: 1.5rem; */
}

.row {
  display: block;
  /* height: calc(100% - 60px); */
}

.footer-about {
  margin-top: 400px;
}

.content-container {
  min-height: calc(100vh + 20px);
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

/* @media(max-width: 1244px) {
  .content-container {
    min-height: calc(100vh + 500px);
  }
}

@media(max-width: 840px) {
  .content-container {
    min-height: calc(100vh + 1000px);
  }
}*/

/* @media(max-width: 400px) {
  .content-container {
    min-height: calc(100vh + 2200px);
  }
} */

.system {
  display: flex;
  color: #000;
  font-weight: bold;
  min-height: 60vh;
  margin-top: -500px;
  justify-content: center;
  z-index: 1;
  text-decoration: none;
}



.img-fluid {
  max-width: 100%;
  height: auto;
}

.contact-form-login {
    margin: 0 auto;
    width: 100%;
    color: #394e6f;
    position: relative;
}

.home-cards {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
  margin-top: 10px;
}

 @media(max-width: 700px) {
   .home-cards {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-left: 0!important;
   }
 }

 .home-card {
   margin-left: 2.5rem;
 }

 @media(max-width: 700px) {
   .home-card {
     margin-left: 0;
   }
 }

.menu-hover:hover {
  color: #c29a59;
  font-size:  1.5rem;
}

.menu-hover:active {
  color: red;
  font-size: 1.5rem;
}

